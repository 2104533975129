<template>
  <section>
    <div class="content-header">
      <h2>Do you have a hidden curriculum?</h2>
    </div>
    <div class="content-wrapper">
      <p>Despite our best efforts, there is still a “hidden curriculum” in our classrooms and curricula—the unwritten rules or expectations for our physical and digital learning spaces.</p>
      <p>Specifically, expectations before, during and after class, as well as what students should do if they miss class, are often unspoken because many faculty assume that their students already know these things or because they feel students should figure them out on their own. </p>
      <p>Nonetheless, some students struggle because they don’t know about these habits, behaviours and strategies. But once they learn them, they can meet them with ease.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
